import React from "react";
import IMG01 from '../../assets/nosotros/nosotros01.jpg'
import IMG02 from '../../assets/nosotros/nosotros02.jpg'
import IMG03 from '../../assets/nosotros/nosotros03.jpg'
import './nosotros.css';
const Nosotros = () => {
    return (
      <div className="nosotros-container">
        <div className="nosotros-content1">
          <div className="nosotros-content1-grid">
            <img src={IMG01} />
            <div
              className="flex flex-col justify-center items-center px-6"
              style={{ background: "#508104", color: "#fff" }}
            >
              <h2
                className="mb-8"
                style={{ color: "#fff", fontSize: 20, fontWeight: "bold" }}
              >
                Coaching Privado
              </h2>
              <p>
                Mediante visita privada el propietario aprende a educar a su
                propio perro
              </p>
            </div>
            <img className="lg-image" src={IMG02} />
          </div>
          <div className="nosotros-content1-grid">
            <div
              className="flex flex-col justify-center items-center px-6"
              style={{ background: "#9E8F02", color: "#fff" }}
            >
              <p>
                ⦁ Entrenamiento con Clicker
                <br />
                ⦁ Hotel
                <br />
                ⦁ Escuela tipo casa
                <br />
              </p>
            </div>
            <img className="lg-image" src={IMG03} />
            <div
              className="flex flex-col justify-center items-center px-6"
              style={{ background: "#C9AF52", color: "#fff" }}
            >
              <h2
                className="mb-8"
                style={{ color: "#fff", fontSize: 20, fontWeight: "bold" }}
              >
                Coaching Virtual
              </h2>
              <p>
                Consultoría en linea, el propietario aprende a educar a su
                propio perro
              </p>
            </div>
          </div>
        </div>
        <div className="nosotros-content2 px-10 py-12">
          <h2 className="project-title" style={{ color: '#000000' }}>Sobre Nosotros</h2>
          <h2 className="project-subtitle" style={{ color: '#508104' }}>Adiestramiento Natural</h2>
          <p>
            Somos una escuela y hotel canino, que basa su educación en el
            empoderamiento emocional y la adaptación social de los perros, bajo
            un sistema propio de entrenamiento, el sistema de inteligencia
            emocional canina, que busca resultados efectivos evitando la presión
            física, maltrato o técnicas de dominancia. <br/>
            <br/>Contamos con 23 años de
            experiencia, años en los que hemos innovado y actualizado
            constantemente los métodos para mejorar nuestros servicios y
            resultados. <br/>
            <br/>Todo nuestro personal está en constante capacitación
            internacional virtual y presencial con diversos cursos formativos
            como diplomados, certificaciones, seminarios, talleres y cursos de
            entrenamiento y agresividad canina.<br/>
            <br/>Estamos en constante capacitacion, damos lo mejor para 
            lograr un proceso satisfactorio. Estamos 100% 
            comprometidos en el bienestar de nuestros perros, 
            proveemos un proceso de educación canina libre de 
            maltrato y sus accesorios (collares de ahorque, púas 
            técnicas de dominancia) ¡Los evitamos totalmente!
          </p>
          <h2 className="mt-6" style={{ color: '#508104', fontSize: 18, fontWeight: 'bold' }}>Somos los primeros en ofrecer en el país</h2>
          <a style={{  color: '#508104', textDecoration: 'underline' }} className="pt-4" href='/terms'>Terminos y restricciones</a>
        </div>
      </div>
    );
}

export default Nosotros;