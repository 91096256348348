import campamentocanino from '../../../assets/services/campamento1.jpg';
import campementocanino1 from '../../../assets/services/club-canino02.jpg';
import campementocanino2 from '../../../assets/services/campamento3.jpg';
import campementocanino3 from '../../../assets/services/campamento-canino.jpg';
import habilidades from '../../../assets/services/habilidades01.jpg';
import './campamentocanino.css';

function ServiceCampamentoCanino() {

    let steps = [
        "Debes participar en las clases virtuales que se imparten por la noche entre semana, se te indicarán los horarios y días al momento de tu inscripción. ",
        "Cancelar el 100% del primer mes al menos un día antes de dejar a tu perro en las instalaciones.",
        "Firmar el contrato (para este se solicita un fiador garante) y enviar la papelería requerida (DPI de ambas personas, el propietario y el fiador), se requiere firma de fiador en el contrato.",
        "Estar al día en la desparacitación interna y externa de tu perro, si no la tienes al día podemos ayudarte por un costo adicional.",
        "Después del primer debes asistir al menos dos veces al mes a entrenar con tu perro y conocer los nuevos protocolos con los que debes trabajar en adelante (no se permiten visitas al perro durante el primer un mes por proceso de adaptación)",
        "Coordina con tu asesor la fecha y hora para dejar a tu perro en las instalaciones, indica si necesitas que lo recogamos por un costo adicional.",
        "Enviar comprobante de pago a tu asesor al número de cuenta"
    ]

    return (
        <div className='min-h-screen'>
            <div className='w-full flex items-center justify-center mb-4 services-hero-description'>
                <h3 className="text-center">Campamento Canino</h3>
            </div>
            <div className="w-full flex flex-col lg:flex-row mb-4">
                <div className='w-full lg:w-1/2 justify-center'>
                    <img className="w-full" src={campamentocanino}></img>
                    <button className="w-full rounded-md py-3 text-center text-white btn-intensivo" onClick={event => window.open('https://wa.link/wm62bj', '_blank')}>Agenda tu cita</button>
                </div>
                <div className='w-full lg:w-1/2 px-12 lg:px-16'>
                    <p className='py-12'>
                        <b>Para inscribirte al campamento canino es importante que tomes en cuenta los siguientes puntos</b>
                    </p>
                    <ul className='mb-10'>
                        {steps.map((step) => (
                            <li className='flex mb-4 items-center mb-6'>
                                <svg style={{ minWidth: 28 }} className='h-5 w-7 brown-bg' xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
                                    <path d="M226.5 92.9c14.3 42.9-.3 86.2-32.6 96.8s-70.1-15.6-84.4-58.5s.3-86.2 32.6-96.8s70.1 15.6 84.4 58.5zM100.4 198.6c18.9 32.4 14.3 70.1-10.2 84.1s-59.7-.9-78.5-33.3S-2.7 179.3 21.8 165.3s59.7 .9 78.5 33.3zM69.2 401.2C121.6 259.9 214.7 224 256 224s134.4 35.9 186.8 177.2c3.6 9.7 5.2 20.1 5.2 30.5v1.6c0 25.8-20.9 46.7-46.7 46.7c-11.5 0-22.9-1.4-34-4.2l-88-22c-15.3-3.8-31.3-3.8-46.6 0l-88 22c-11.1 2.8-22.5 4.2-34 4.2C84.9 480 64 459.1 64 433.3v-1.6c0-10.4 1.6-20.8 5.2-30.5zM421.8 282.7c-24.5-14-29.1-51.7-10.2-84.1s54-47.3 78.5-33.3s29.1 51.7 10.2 84.1s-54 47.3-78.5 33.3zM310.1 189.7c-32.3-10.6-46.9-53.9-32.6-96.8s52.1-69.1 84.4-58.5s46.9 53.9 32.6 96.8s-52.1 69.1-84.4 58.5z" />
                                </svg>
                                <span className='pl-9'>{step}</span>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
            <div className='price-title text-center py-5'>
                <h3>Costo: Q1,800.ºº por mes</h3>
                <p>No incluye comida, gastos veterinarios o transporte</p>
            </div>
            <div className='container mx-auto py-12'>
                <img className="w-full" src={habilidades}></img>
                {/* <div className='w-full flex flex-col lg:flex-row'>
                    <img className="w-full lg:w-1/3" src={campementocanino1}></img>
                    <img className="w-full lg:w-1/3" src={campementocanino2}></img>
                    <img className="w-full lg:w-1/3" src={campementocanino3}></img>
                </div> */}
            </div>
        </div>
    );
}

export default ServiceCampamentoCanino;