
import logo from "../../assets/logo.jpeg";
import whatsapp from "../../assets/whatsapp.png";
import facebook from "../../assets/facebook.png";
import instagram from "../../assets/instagram.png";
import routes from "../../utils/routes";
import "./footer.css";

function Footer() {

    const redirectRoutes = {
        FACEBOOK: 'https://www.facebook.com/Adiestramiento.Natural/', 
        WHATSAPP: 'https://wa.link/wm62bj', 
        INSTAGRAM: 'https://www.instagram.com/adiestramiento.natural/' 
    }

    return (
        <div className="footer">
            <div className="footer-content container m-auto">
                <div className="footer-logo">
                    <img alt="adiestramiento-natural" src={logo}></img>
                </div>
                <div className="footer-links">
                    <p className="font-bold">Adiestramiento Natural</p>
                    {routes.map((r) => {
                        return <a className="footer_social_media" href={r.path}>{r.routeName}</a>
                    })}
                    <a className="footer_social_media" href={'/terms'}>Terminos y restricciones</a>

                </div>
                <div>
                    <p className="font-bold mb-2 contact-title" style={{ color: '#fff' }}>Contacto</p>
                    <div className="footer-contact pr-4">
                        <a href={redirectRoutes.WHATSAPP} className="flex items-center justify-center">
                            <img  alt="whatsapp" src={whatsapp} style={{ width: 24 }}></img>
                        </a>
                        <a href={redirectRoutes.FACEBOOK} className="flex items-center justify-center ml-5 mr-6">
                            <img alt="facebook" src={facebook} style={{ width: 22 }}></img>
                        </a>
                        <a href={redirectRoutes.INSTAGRAM} className="flex items-center justify-center">
                            <img alt="instagram" src={instagram} style={{ width: 22 }}></img>

                        </a>
                    </div>
                </div>
                <div style={{ maxWidth: 220 }}>
                    <p className="font-bold mb-2 contact-title" style={{ color: '#fff' }}>Ubicación</p>
                    <div className="footer-contact pr-8">
                        <p style={{ fontSize: 15 }}>Villa Canales a 15 minutos de Carretera a El Salvador, Guatemala</p>
                    </div>
                </div>
                
            </div>
            <div className="footer-content-2">
                <div className="container m-auto flex justify-between flex-col sm:flex-row">
                    <p style={{ fontSize: 16 }}>{new Date().getFullYear()} @ Adiestramiento Natural Guatemala</p>
                    <p style={{ fontSize: 16 }}>Desarrollado por <a href="https://laynot.com" target="_blank">laynot.com</a></p>
                </div>
            </div>
            
        </div>
    );
}

export default Footer;