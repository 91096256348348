import clubcanino from '../../../assets/services/club-canino02.jpg';
import './clubcanino.css';

function ServiceClubCanino() {

  let steps = [
    "Parque Ecologico en la Zona 16 (sábados o domingos) El parque cobra entrada Q15 por persona y Q15 por vehículo",
    "Zona 11 frente a la colonia Majaditas, camino a la comunidad, (domingos)",
    "Además, ofrecemos clases virtuales los dias lunes y martes por la noche, lo que te permite aprender desde la comodidad de tu hogar.",
  ]

  let options = [
    "Promo 2 meses por Q550 (precio normal Q650)",
    "Inscripción por 1 mes por Q300 más tarifa de inscripción de Q50. Si no puedes asistir a una clase, ¡no hay problema! Puedes reponerla en un horario diferente para que no te pierdas nada"
  ]

  return (
    <div className="min-h-screen">
      <div className="w-full flex items-center justify-center mb-4 club-canino-hero-description">
        <h3 className="text-center">Club Canino</h3>
      </div>
      <div className="w-full flex flex-col lg:flex-row mb-4">
        <div className='w-full lg:w-1/2 justify-center'>
          <img className="w-full" src={clubcanino}></img>
          <button className="w-full rounded-md py-3 text-center text-white btn-intensivo" onClick={event => window.open('https://wa.link/wm62bj', '_blank')}>Agenda tu cita</button>
        </div>
        <div className='w-full lg:w-1/2 px-12 lg:px-16'>
          <p className='py-12'>
            <b>Ofrecemos opciones de clases presenciales en dos sedes diferentes</b>
          </p>
          <ul>
            {steps.map((step) => (
              <li className='flex mb-4 items-center mb-6'>
                <svg className='h-5 w-7 brown-bg' xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
                  <path d="M226.5 92.9c14.3 42.9-.3 86.2-32.6 96.8s-70.1-15.6-84.4-58.5s.3-86.2 32.6-96.8s70.1 15.6 84.4 58.5zM100.4 198.6c18.9 32.4 14.3 70.1-10.2 84.1s-59.7-.9-78.5-33.3S-2.7 179.3 21.8 165.3s59.7 .9 78.5 33.3zM69.2 401.2C121.6 259.9 214.7 224 256 224s134.4 35.9 186.8 177.2c3.6 9.7 5.2 20.1 5.2 30.5v1.6c0 25.8-20.9 46.7-46.7 46.7c-11.5 0-22.9-1.4-34-4.2l-88-22c-15.3-3.8-31.3-3.8-46.6 0l-88 22c-11.1 2.8-22.5 4.2-34 4.2C84.9 480 64 459.1 64 433.3v-1.6c0-10.4 1.6-20.8 5.2-30.5zM421.8 282.7c-24.5-14-29.1-51.7-10.2-84.1s54-47.3 78.5-33.3s29.1 51.7 10.2 84.1s-54 47.3-78.5 33.3zM310.1 189.7c-32.3-10.6-46.9-53.9-32.6-96.8s52.1-69.1 84.4-58.5s46.9 53.9 32.6 96.8s-52.1 69.1-84.4 58.5z" />
                </svg>
                <span className='pl-9'>{step}</span>
              </li>
            ))}
          </ul>
          <p className='py-6'>
            <b>Ofrecemos dos opciones de inscripción</b>
          </p>
          <ul>
            {options.map((step) => (
              <li className='flex mb-4 items-center mb-6'>
                <svg className='h-5 w-7 brown-bg' xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
                  <path d="M226.5 92.9c14.3 42.9-.3 86.2-32.6 96.8s-70.1-15.6-84.4-58.5s.3-86.2 32.6-96.8s70.1 15.6 84.4 58.5zM100.4 198.6c18.9 32.4 14.3 70.1-10.2 84.1s-59.7-.9-78.5-33.3S-2.7 179.3 21.8 165.3s59.7 .9 78.5 33.3zM69.2 401.2C121.6 259.9 214.7 224 256 224s134.4 35.9 186.8 177.2c3.6 9.7 5.2 20.1 5.2 30.5v1.6c0 25.8-20.9 46.7-46.7 46.7c-11.5 0-22.9-1.4-34-4.2l-88-22c-15.3-3.8-31.3-3.8-46.6 0l-88 22c-11.1 2.8-22.5 4.2-34 4.2C84.9 480 64 459.1 64 433.3v-1.6c0-10.4 1.6-20.8 5.2-30.5zM421.8 282.7c-24.5-14-29.1-51.7-10.2-84.1s54-47.3 78.5-33.3s29.1 51.7 10.2 84.1s-54 47.3-78.5 33.3zM310.1 189.7c-32.3-10.6-46.9-53.9-32.6-96.8s52.1-69.1 84.4-58.5s46.9 53.9 32.6 96.8s-52.1 69.1-84.4 58.5z" />
                </svg>
                <span className='pl-9'>{step}</span>
              </li>
            ))}
          </ul>
          <p className='pb-10'>
            <b>Es necesario estar inscrito para poder asistir.</b>
          </p>
        </div>
      </div>
    </div>
  );
}

export default ServiceClubCanino;