import './home.css';

import imgHero02 from '../../assets/hero02.JPG';
import React from "react";
import Slider from "react-slick";

import IMG01 from '../../assets/home/home-img01.jpg';
import logo from '../../assets/logo.jpeg';

import SERVICE01 from '../../assets/servicios-img/s01.png';
import SERVICE02 from '../../assets/servicios-img/s02.png';
import SERVICE03 from '../../assets/servicios-img/s03.png';
import SERVICE04 from '../../assets/servicios-img/s04.png';
import locationIMG from '../../assets/home/location-img.jpg';
import MoreInfoButton from '../../components/more-info-button/more-info-button';

function Home() {

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };

  const settings2 = {
    dots: false,
    infinite: true,
    speed: 500,
    autoplaySpeed: 1000,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          arrows: false,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          arrows: false,
        }
      },
    ]
  };

    return (
      <div className="home-content">
        <Slider {...settings} className="home-slider">
          <div>
            <div className="home-hero01"></div>
          </div>
        </Slider>
        <Slider {...settings} className="home-slider-sm">
          <div>
            <div className="home-hero01"></div>
          </div>
        </Slider>

        <div className="home-content-01 container mx-auto">
          <div className="home-content-01-section1">
            <div className="home-content-01-section1-color"></div>
            <img src={IMG01} />
          </div>
          <div className="home-content-01-section2">
            <p>
              Te proveemos un lugar apto para la meditación, la reestructuración
              cognitiva, ambiental, emocional y social para tu perro (CAES)
              <br />
              Un efectivo sistema terepeutico donde tu perro encotrara el
              balance entre sus necesidades naturales y su adaptacion al mundo
              humano.
              <br />
              <br />
              Trabajamos las siguientes áreas:
              <br />
              ⦁ Gestion Emocional Canina
              <br />
              ⦁ Senderismo en nuestro bosque privado
              <br />
              ⦁ Manejo Urbano
              <br />
              ⦁ Interacciones sociales con otros perros y personas
              <br />
              ⦁ Problemas de comportamiento Tratamiento de la agresion
              <br />
              No importa la edad, la raza o el problema. Brindamos servicios
              desde el 2004,
              <br /> buscamos armonia entre los perros y sus propetarios.
              <br />
              <br />
              ¡Contamos con 10,000 Vr2 de espacio!
            </p>
            <img src={logo} style={{ width: 250, height: 250 }} />
          </div>
        </div>

        <div className="home-services-container container">
          <h1 className="project-title">Conoce nuestros servicios</h1>
          <div className="home-services-list-content container">
            <div className="home-services-item">
              <div className="home-services-item-text text-left">
                <h2 className="project-subtitle">Servicio Intensivo</h2>
                <p>
                  Nuestro Adiestrador te enseñará a ti y a tu familia a convertirte en 
                  el entrenador de tu propio perro en la comodidad de tu hogar. 
                  <br />
                  <br />En este servicio el adiestrador te indica los ejercicios, teoría 
                  y práctica que debes tener a partir de ahora con los nuevos protocolos de manejo con tu perro.
                  <br />
                  <br /> Para lograr resultados es necesario que como propietario 
                  realices cambios de hábitos, disciplina y sigas las recomendaciones del entrenador. 
                  <br />
                  <br />Este es el servicio más rápido para obtener resultados.
                </p>
                <MoreInfoButton href={"/servicio-intensivo"}>Mas información</MoreInfoButton>
              </div>
              <img src={SERVICE01}></img>
            </div>
            <div className="home-services-item">
              <div className="home-services-item-text text-left">
                <h2 className="project-subtitle">Servicio Intensivo Virtual</h2>
                <p>
                  Nuestro Adiestrador te enseñará a ti y a tu familia cómo 
                  convertirte en el entrenador de tu propio perro a través de sesiones de zoom de una hora.
                  <br />
                  <br />En este servicio el adiestrador te indica los ejercicios, teoría 
                  y práctica que debes tener a partir de ahora con los nuevos protocolos de manejo con tu perro.
                  <br />
                  <br /> Para lograr resultados es necesario que como propietario 
                  realices cambios de hábitos, disciplina y sigas las recomendaciones del entrenador. 
                  <br />
                  <br />Este es el servicio más rápido para obtener resultados.
                </p>
                <MoreInfoButton href={"/servicio-intensivo-virtual"}>Mas información</MoreInfoButton>
              </div>
              <img src={SERVICE01}></img>
            </div>
            <div className="home-services-item">
              <div className="home-services-item-text text-left">
                <h2 className="project-subtitle">Campamento Canino</h2>
                <p>
                  Una opción donde nosotros iniciamos la educación de tu 
                  perro en los objetivos que necesites.
                  <br />
                  <br />
                  Tu perro se hospedará en nuestras instalaciones, recibirás 
                  su progreso a través de vídeos y fotografías, para visitar a 
                  tu perro se programa la visita a partir del segundo mes. <br />
                  <br />
                  Debes obligatoriamente visitar al perro como mínimo cada 
                  15 días a partir del segundo mes para aprender los nuevos 
                  procesos de manejo y comunicación con tu perro.<br />
                  <br />
                  Progresivamente te sentirás seguro para poder realizar los 
                  ejercicios en simulaciones controladas. El promedio de 
                  estadía es de 3 a 4 meses (dependiendo de los objetivos).
                </p>
                <MoreInfoButton href={"/campamento-canino"}>Mas información</MoreInfoButton>
              </div>
              <img src={SERVICE02}></img>
            </div>
            <div className="home-services-item">
              <div className="home-services-item-text text-left">
                <h2 className="project-subtitle">Day Care & Hotel</h2>
                <p>
                  Cuidamos a tu perro los dias que necesites, donde contará con 
                  intereacciones sociales con otros perros y paseos recreativos, así 
                  como las bases de protocolo de gestión emocional. 
                  <br />
                  <br />
                  Tenemos precios super especiales para temporadas largas.
                </p>
                <MoreInfoButton href={"/daycare-hotel"}>Mas información</MoreInfoButton>
              </div>
              <img src={SERVICE03}></img>
            </div>
            <div className="home-services-item">
              <div className="home-services-item-text text-left">
                <h2 className="project-subtitle">Club Canino</h2>
                <p>
                  Nos acompañas en nuestras clases grupales junto a tu perro los 
                  sábados o domingos en zona 11 o zona 16, dependerá del club 
                  que se acomode a tus necesidades. <br />
                  <br />
                  Los servicios del club se dividen en perros sociables y perros
                  reactivos, te ayudamos a resolver los problemas de
                  comportamiento en el hogar y problemas de reactividad,
                  nuestros servicios te ayudarán a cumplir tus objetivos sin
                  importar la raza, edad o problema
                </p>
                <MoreInfoButton href={"/club-canino"}>Mas información</MoreInfoButton>
              </div>
              <img src={SERVICE04}></img>
            </div>
          </div>
        </div>
        <div className="home-location-container my-10">
          <div className="home-location-text">
            <h2 className='project-subtitle mb-12 text-center'>Condiciones y restricciones de los servicios</h2>
            <p>
              El propietario tiene un máximo de cancelación o reposición de 
              clase unicamente 2 veces consecutivas, en el caso del club 
              deberá iniciar el mes con el pago correspondiente y en el caso 
              del servicio privado deberá programarse con el tiempo debido 
              según lo estandarice el contrat.o <br/>
              <br/>El propietario se compromete a realizar los ejercicios, 
              conectarse a las clases y realizar el seguimiento necesario 
              para obtener resultados en cualquiera de nuestros servicios.<br/>
              <br/>Los servicios que requieren contrato deben obligatoriamente 
              tener información veridica y completa.<br/>
              <br/>No se realizan devoluciones.<br/><br/>
              <b>En nuestras instalaciones:</b>
              <br/>En relación al Adiestramiento Natural, es importante resaltar 
              que no asumimos responsabilidad por el cuidado, alojamiento 
              o financiamiento de perros cuyos propietarios desaparezcan. 
              En tal situación, se seguirán las medidas legales pertinentes
            </p>
          </div>
        </div>
        <div className='w-full my-10 px-12 lg:px-16'>
          <img src={locationIMG} style={{ width: '70px', height: '70px', objectFit: 'cover', borderRadius: '150px' }}/>
          <div className='w-full'>
            <b>Ubicación:</b>
            <br/>Nuestro campamento se encuentra en Villa Canales a 15 minutos 
            de carretera a El Salvador o a 15 minutos de la autopista VAS. 
            <br/>Para conocer es necesario agendar previa cita de Lunes a Sábado
          </div>
        </div>
      </div>
    );
  }
  
  export default Home;
  