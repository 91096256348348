import Contact from "../views/contact/contact";
import Home from "../views/home/home";
import Nosotros from "../views/nosotros/nosotros";
import Services from "../views/services/services";

const routes = [
    {
        routeName: 'Inicio',
        component: <Home/>,
        path: '/'
    },
    /*{
        routeName: 'Servicios',
        component: <Services/>,
        path: '/services'
    },*/
    {
        routeName: 'Nosotros',
        component: <Nosotros/>,
        path: '/nosotros'
    },
    {
        routeName: 'Contacto',
        component: <Contact/>,
        path: '/contact'
    },
]

export default routes;