import './contact.css'
import logo from '../../assets/contact/ubicacion.jpg';

function Contact() {
  return (
    <div className='min-h-full py-12'>
      <h1 className="project-title text-center">
        Contáctanos
      </h1>
      <div className='flex flex-col lg:flex-row w-11/12 lg:w-8/12 rounded-xl container mx-auto shadow-lg mt-8 py-10'>
        <div className='w-full lg:w-1/2 py-16 px-5 lg:px-12'>
          <form>
            {/* USERNAME */}
            <label className="relative block mb-6">
              <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                <svg className="h-4 w-5 fill-slate-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                  <path d="M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304H178.3z" />
                </svg>
              </span>
              <input className="block bg-white w-full border rounded-md py-2 pl-9 pr-3 shadow-sm focus:outline-none focus:ring-1 sm:text-sm" placeholder="Tu nombre" type="text" id="username" />
            </label>
            {/* USER MAIL */}
            <label className="relative block mb-6">
              <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                <svg className="h-4 w-5 fill-slate-400" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
                  <path d="M48 64C21.5 64 0 85.5 0 112c0 15.1 7.1 29.3 19.2 38.4L236.8 313.6c11.4 8.5 27 8.5 38.4 0L492.8 150.4c12.1-9.1 19.2-23.3 19.2-38.4c0-26.5-21.5-48-48-48H48zM0 176V384c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V176L294.4 339.2c-22.8 17.1-54 17.1-76.8 0L0 176z" /></svg>
              </span>
              <input className="block bg-white w-full border rounded-md py-2 pl-9 pr-3 shadow-sm focus:outline-none sm:text-sm" placeholder="Tu correo electronico" type="email" id="email" />
            </label>
            {/* USER PHONE */}
            <label className="relative block mb-6">
              <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                <svg className="h-4 w-5 fill-slate-400" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
                  <path d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z" /></svg>
              </span>
              <input className="block bg-white w-full border rounded-md py-2 pl-9 pr-3 shadow-sm focus:outline-none sm:text-sm" placeholder="Tu numero de contacto" type="phone" id="phone" />
            </label>
            {/* USER COMMENTS */}
            <label className="relative block mb-6">
              <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                <svg className="h-4 w-5 fill-slate-400" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
                  <path d="M512 240c0 114.9-114.6 208-256 208c-37.1 0-72.3-6.4-104.1-17.9c-11.9 8.7-31.3 20.6-54.3 30.6C73.6 471.1 44.7 480 16 480c-6.5 0-12.3-3.9-14.8-9.9c-2.5-6-1.1-12.8 3.4-17.4l0 0 0 0 0 0 0 0 .3-.3c.3-.3 .7-.7 1.3-1.4c1.1-1.2 2.8-3.1 4.9-5.7c4.1-5 9.6-12.4 15.2-21.6c10-16.6 19.5-38.4 21.4-62.9C17.7 326.8 0 285.1 0 240C0 125.1 114.6 32 256 32s256 93.1 256 208z" /></svg>
              </span>
              <textarea className="block bg-white w-full border rounded-md py-2 pl-9 pr-3 shadow-sm focus:outline-none sm:text-sm" placeholder="Tus comentarios" type="text" id="comments" />
            </label>
            <button className="w-full rounded-md py-3 text-center text-white contact-button" >
              Submit
            </button>
          </form>
        </div>
        <div className='w-full lg:w-1/2 py-16 px-5 lg:px-12'>
          <img className="img-logo" src={logo} />
          {[
            "Nos encontramos en Villa Canales a 15 minutos de la autopista VAS, Carretera a El Salvador, Guatemala",
            "+502 3863-6184",
            "adiestramientonatural@gmail.com"
          ].map((contact) => (
            <div className="flex mb-4 items-center">
              <svg style={{ minWidth: 28 }} className='h-5 w-7 brown-bg' xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
                <path d="M226.5 92.9c14.3 42.9-.3 86.2-32.6 96.8s-70.1-15.6-84.4-58.5s.3-86.2 32.6-96.8s70.1 15.6 84.4 58.5zM100.4 198.6c18.9 32.4 14.3 70.1-10.2 84.1s-59.7-.9-78.5-33.3S-2.7 179.3 21.8 165.3s59.7 .9 78.5 33.3zM69.2 401.2C121.6 259.9 214.7 224 256 224s134.4 35.9 186.8 177.2c3.6 9.7 5.2 20.1 5.2 30.5v1.6c0 25.8-20.9 46.7-46.7 46.7c-11.5 0-22.9-1.4-34-4.2l-88-22c-15.3-3.8-31.3-3.8-46.6 0l-88 22c-11.1 2.8-22.5 4.2-34 4.2C84.9 480 64 459.1 64 433.3v-1.6c0-10.4 1.6-20.8 5.2-30.5zM421.8 282.7c-24.5-14-29.1-51.7-10.2-84.1s54-47.3 78.5-33.3s29.1 51.7 10.2 84.1s-54 47.3-78.5 33.3zM310.1 189.7c-32.3-10.6-46.9-53.9-32.6-96.8s52.1-69.1 84.4-58.5s46.9 53.9 32.6 96.8s-52.1 69.1-84.4 58.5z" /></svg>
              <p className='sm:text-sm pl-9'>{contact}</p>
            </div>
          ))}
          <button className="w-full rounded-md py-2 text-center text-white btn-intensivo" onClick={event => window.open('https://wa.link/wm62bj', '_blank')}>Agenda tu cita</button>
        </div>
      </div>
    </div>
  );
}

export default Contact;
