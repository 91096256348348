
import hero01IMG from '../../assets/services/services_hero01.jpg';
import campamentoCanino2 from '../../assets/services/campamento-canino-2.jpg';
import daycarehotel02 from '../../assets/services/daycare-hotel02.jpg';
import socializacion02 from '../../assets/services/socializacion02.jpg';
import habilidades02 from '../../assets/services/habilidades02.jpg';
import serviciointensivo02 from '../../assets/services/servicio-intensivo02.jpg';
import clubcanino from '../../assets/services/club-canino02.jpg';
import './services.css';

function Services() {
    return (
      <div className='services-content py-6'>
        <h1 className="project-title text-center">
          Nuestros Servicios
        </h1>
        <img src={hero01IMG} className='w-full mt-8 mb-12' style={{ objectFit: 'cover', minHeight: 340,  }}></img>

        <h1 className="project-title text-center">
          Campamento Canino
        </h1>
        <div className="w-full flex items-center justify-center mt-8 mb-4 services-hero02-description">
          <p>
            En "Adiestramiento Natural", cada perro es tratado con el amor y
            respeto que se merece. ¡Nuestra misión es brindarles una experiencia
            positiva y hacer que se sientan amados y cuidados en todo momento!
          </p>
        </div>
        <div className="services-campamento-canino-02">
          <img className='w-full' src={campamentoCanino2}></img>
          <p>
            El entrenamiento canino se basa en técnicas de refuerzo positivo,
            donde se recomensa el buen comportamiento y se recompensa el buen
            comportamiento y se desalientan las conductas no deseadas. Mediante
            la enseñanza de comandos básicos, límites y rutinas, logragrás una
            relación más sólida y armoniosa con tu mascota.
          </p>
        </div>

        <h1 className="project-title text-center mt-12">
          Day Care & Hotel
        </h1>
        <div className="w-full flex items-center justify-center mt-8 mb-4 services-hero03-description">
          <p>
            En "Adiestramiento Natural", cada perro es tratado con el amor y
            respeto que se merece. ¡Nuestra misión es brindarles una experiencia
            positiva y hacer que se sientan amados y cuidados en todo momento!
          </p>
        </div>
        <div className="services-campamento-canino-02">
          <img className='w-full' src={daycarehotel02}></img>
          <p>
            El entrenamiento canino se basa en técnicas de refuerzo positivo,
            donde se recomensa el buen comportamiento y se recompensa el buen
            comportamiento y se desalientan las conductas no deseadas. Mediante
            la enseñanza de comandos básicos, límites y rutinas, logragrás una
            relación más sólida y armoniosa con tu mascota.
          </p>
        </div>

        <h1 className="project-title text-center mt-12">
          Socialización
        </h1>
        <div className="w-full flex items-center justify-center mt-8 mb-4 services-hero04-description">
          <p>
            En "Adiestramiento Natural", cada perro es tratado con el amor y
            respeto que se merece. ¡Nuestra misión es brindarles una experiencia
            positiva y hacer que se sientan amados y cuidados en todo momento!
          </p>
        </div>
        <div className="services-campamento-canino-02">
          <p>
            El entrenamiento canino se basa en técnicas de refuerzo positivo,
            donde se recomensa el buen comportamiento y se recompensa el buen
            comportamiento y se desalientan las conductas no deseadas. Mediante
            la enseñanza de comandos básicos, límites y rutinas, logragrás una
            relación más sólida y armoniosa con tu mascota.
          </p>
          <img className='w-full' src={socializacion02}></img>
        </div>
        <div className="w-full flex items-center justify-center mt-8 mb-4 services-hero05-description">
          <p>
            En "Adiestramiento Natural", cada perro es tratado con el amor y
            respeto que se merece. ¡Nuestra misión es brindarles una experiencia
            positiva y hacer que se sientan amados y cuidados en todo momento!
          </p>
        </div>

        <h1 className="project-title text-center mt-12">
          Habilidades
        </h1>
        <div className="w-full flex items-center justify-center mt-8 mb-4 services-hero06-description">
          <p>
            En "Adiestramiento Natural", cada perro es tratado con el amor y
            respeto que se merece. ¡Nuestra misión es brindarles una experiencia
            positiva y hacer que se sientan amados y cuidados en todo momento!
          </p>
        </div>
        <div className="services-campamento-canino-02">
          <img className='w-full' src={habilidades02}></img>
          <p>
            El entrenamiento canino se basa en técnicas de refuerzo positivo,
            donde se recomensa el buen comportamiento y se recompensa el buen
            comportamiento y se desalientan las conductas no deseadas. Mediante
            la enseñanza de comandos básicos, límites y rutinas, logragrás una
            relación más sólida y armoniosa con tu mascota.
          </p>
        </div>

        <h1 className="project-title text-center mt-12">
          Servicio intesivo
        </h1>
        <div className="w-full flex items-center justify-center mt-8 mb-4 services-hero07-description">
          <p>
            En "Adiestramiento Natural", cada perro es tratado con el amor y
            respeto que se merece. ¡Nuestra misión es brindarles una experiencia
            positiva y hacer que se sientan amados y cuidados en todo momento!
          </p>
        </div>
        <div className="services-campamento-canino-02">
          <p>
            El entrenamiento canino se basa en técnicas de refuerzo positivo,
            donde se recomensa el buen comportamiento y se recompensa el buen
            comportamiento y se desalientan las conductas no deseadas. Mediante
            la enseñanza de comandos básicos, límites y rutinas, logragrás una
            relación más sólida y armoniosa con tu mascota.
          </p>
          <img className='w-full' src={serviciointensivo02}></img>
        </div>

        <h1 className="project-title text-center mt-12">
          Club Canino
        </h1>
        <div className="w-full flex items-center justify-center mt-8 mb-4 services-hero08-description">
          <p>
            En "Adiestramiento Natural", cada perro es tratado con el amor y
            respeto que se merece. ¡Nuestra misión es brindarles una experiencia
            positiva y hacer que se sientan amados y cuidados en todo momento!
          </p>
        </div>
        <div className="services-campamento-canino-02">
        <img className='w-full' src={clubcanino}></img>
          <p>
            El entrenamiento canino se basa en técnicas de refuerzo positivo,
            donde se recomensa el buen comportamiento y se recompensa el buen
            comportamiento y se desalientan las conductas no deseadas. Mediante
            la enseñanza de comandos básicos, límites y rutinas, logragrás una
            relación más sólida y armoniosa con tu mascota.
          </p>
        </div>
      </div>
    );
  }
  
  export default Services;
  