import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './index.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import reportWebVitals from './reportWebVitals';
import Layout from './layout/adiestramiento-layout';
import routes from './utils/routes';
import ServiceClubCanino from './views/services/clubcanino/clubcanino';
import ServiceDayCareHotel from './views/services/daycare&hotel/daycare&hotel';
import ServiceCampamentoCanino from './views/services/campamentocanino/campamentocanino';
import ServiceIntensivo from './views/services/intensivo/intensivo';
import ServiceIntensivoVirtual from './views/services/intensivovirtual/intensivovirtual';
import Terms from './views/terms/terms';

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/">
          {routes.map((r) => {
          return <Route path={r.path} element={r.component} />
          })}
          <Route path={'club-canino'} element={<ServiceClubCanino/>} />
          <Route path={'daycare-hotel'} element={<ServiceDayCareHotel/>} />
          <Route path={'campamento-canino'} element={<ServiceCampamentoCanino/>} />
          <Route path={'servicio-intensivo'} element={<ServiceIntensivo/>} />
          <Route path={'servicio-intensivo-virtual'} element={<ServiceIntensivoVirtual/>} />
          <Route path={'terms'} element={<Terms/>} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Layout>
      <App />
    </Layout>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
