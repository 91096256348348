import termsIMG from '../../assets/terms.jpg';
import './terms.css';

function Terms() {
    const steps = [
        <span className='pl-9'>El propietario tiene un máximo de cancelación o reposición de clase unicamente 2 veces consecutivas, en el caso del club deberá iniciar el mes con el pago correspondiente y en el caso del servicio privado deberá programarse con el tiempo debido según lo estandarice el contrato.</span>,
        <span className='pl-9'>El propietario se compromete a realizar los ejercicios, conectarse a las clases y realizar el seguimiento necesario para obtener resultados en cualquiera de nuestros servicios.</span>,
        <span className='pl-9'>Los servicios que requieren contrato deben obligatoriamente tener información veridica y completa.</span>,
        <span className='pl-9'>No se realizan devoluciones.</span>,
        <span className='pl-9'>En nuestras instalaciones: <br/>en relación al Adiestramiento Natural, es importante resaltar que no asumimos responsabilidad por el cuidado, alojamiento o financiamiento de perros cuyos propietarios desaparezcan. En tal situación, se seguirán las medidas legales pertinentes.</span>
    ];

  return (
    <div className="min-h-screen">
      <div className="w-full flex items-center justify-center mb-4 services-hero03-description">
        <h3 className="text-center project-title">Terminos y restricciones de los servicios</h3>
      </div>
      <div className="w-full flex flex-col lg:flex-row mb-4 px-6 sm:px-10 py-10">
      <ul className='mb-10'>
            {steps.map((step) => (
              <li className='flex mb-4 items-center mb-6'>
                <svg style={{ minWidth: 22 }} className='brown-bg' xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
                  <path d="M226.5 92.9c14.3 42.9-.3 86.2-32.6 96.8s-70.1-15.6-84.4-58.5s.3-86.2 32.6-96.8s70.1 15.6 84.4 58.5zM100.4 198.6c18.9 32.4 14.3 70.1-10.2 84.1s-59.7-.9-78.5-33.3S-2.7 179.3 21.8 165.3s59.7 .9 78.5 33.3zM69.2 401.2C121.6 259.9 214.7 224 256 224s134.4 35.9 186.8 177.2c3.6 9.7 5.2 20.1 5.2 30.5v1.6c0 25.8-20.9 46.7-46.7 46.7c-11.5 0-22.9-1.4-34-4.2l-88-22c-15.3-3.8-31.3-3.8-46.6 0l-88 22c-11.1 2.8-22.5 4.2-34 4.2C84.9 480 64 459.1 64 433.3v-1.6c0-10.4 1.6-20.8 5.2-30.5zM421.8 282.7c-24.5-14-29.1-51.7-10.2-84.1s54-47.3 78.5-33.3s29.1 51.7 10.2 84.1s-54 47.3-78.5 33.3zM310.1 189.7c-32.3-10.6-46.9-53.9-32.6-96.8s52.1-69.1 84.4-58.5s46.9 53.9 32.6 96.8s-52.1 69.1-84.4 58.5z" />
                </svg>
                {step}
              </li>
            ))}
          </ul>
      </div>
    </div>
  );
}

export default Terms;