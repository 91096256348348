import './header.css';

import logo from '../../assets/logo.jpeg';
import routes from '../../utils/routes';
import { useRef } from 'react';

function Header() {
    const mobileMenuItems = useRef();

    const showMobileMenu = (e) => {
        mobileMenuItems.current.style.display = mobileMenuItems.current.style.display == 'none' ? 'flex' : 'none';
    }

    return (
      <div>
        <div className="site-header">
          <div className="site-header-content">
            <div className="site-header-logo-container">
              <img src={logo}></img>
              <h1>Adiestramiento Natural Canino</h1>
            </div>
            <div className="site-header-links-container">
              {routes.map((r) => {
                return (
                  <a href={r.path} className="site-header-links-item">
                    {r.routeName}
                  </a>
                );
              })}
            </div>
            <div className="site-header-mobile-menu">
            <div
                onClick={showMobileMenu}
                  className="menu-icon"
                >
                  
                </div>
            </div>
          </div>
        </div>
        <div className="site-header-spacer"></div>
        <div className='site-header-mobile-menu-items' ref={mobileMenuItems}>
            {routes.map((r) => {
                return (
                  <a href={r.path} className="site-header-links-item-mobile">
                    {r.routeName}
                  </a>
                );
              })}
        </div>
      </div>
    );
}

export default Header;